/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

import { ParallaxProvider } from "react-scroll-parallax";

import "@reach/skip-nav/styles.css";

import Footer from "./Footer";
import SkipNavLink from "./SkipNavLink";
import { reset } from "../styles";
import i18n from "../../config/i18n";

import "typeface-inter";

const globalStyle = css`
	${reset}

	a {
		color: inherit;
		&:hover {
			opacity: 0.5;
		}
	}

	.parallax-inner {
		/* https://easings.net/en#easeOutCubic */
		transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
	}
`;

const LocaleSwitcher = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 1rem;
`;

const LocaleContext = React.createContext();

const Layout = ({ children, pageContext: { locale } }) => {
	return (
		<ParallaxProvider>
			<LocaleContext.Provider value={{ locale, i18n }}>
				<div className='Layout'>
					<Global styles={globalStyle} />
					<SkipNavLink />
					<LocaleSwitcher data-name='locale-switcher'>
						{/* <Link hrefLang="de-de" to="/">
					DE
				</Link>{' '}
				/{' '}
				<Link hrefLang="en-gb" to="/en">
					EN
				</Link> */}
					</LocaleSwitcher>

					<div className='content'>{children}</div>
					{/* <Footer>
					<div dangerouslySetInnerHTML={{ __html: footer }} />
				</Footer> */}
				</div>
			</LocaleContext.Provider>
		</ParallaxProvider>
	);
};

export { LocaleContext, Layout };
