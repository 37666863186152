module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/projects your pathPrefix should be "projects"
  title: 'supertime', // Navigation and Site Title
  titleAlt: 'supertime - Tracking made easy', // Title for JSONLD
  description: 'supertime redefines how people evaluate time',
  headline: '', // Headline for schema.org JSONLD
  url: 'https://www.supertime.app', // Domain of your site. No trailing slash!
  logo: '/logos/logo-1024.png', // Used for SEO
  ogLanguage: 'de_DE', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'supertime', // shortname for manifest. MUST be shorter than 12 characters
  author: 'supertime', // Author for schemaORGJSONLD
  themeColor: '#000000',
  backgroundColor: '#000000',

  twitter: '', // Twitter Username
  facebook: '', // Facebook Site Name
  googleAnalyticsID: '',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
